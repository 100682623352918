import { useState, useEffect } from "react";
// mui
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import Iconify from "../components/Iconify";
import { Camera } from "../camera";

// utils
import axios from "../utils/axios";

export default function BasicTable() {
  const [userSave, setUserSave] = useState([]);
  const [users, setUser] = useState([]);
  const [busca, setBusca] = useState("");

  function filterByValue(array, string) {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        o[k].toLowerCase().includes(string.toLowerCase())
      )
    );
  }

  const loadData = async () => {
    // Requests
    const usersRes = await axios.get(`/users.php?link=123`);

    if (usersRes.status) {
      const reuslt = usersRes.data.sort(function (a, b) {
        return a.name - b.name;
      });
      setUser(reuslt);
      setUserSave(reuslt);
    }
  };
  // Effects
  useEffect(() => {
    // Initial Effect
    const fristLoad = async () => {
      // Requests
      const usersRes = await axios.get(`/users.php?link=123`);

      if (usersRes.status) {
        const reuslt = usersRes.data.sort(function (a, b) {
          return a.name - b.name;
        });
        setUser(reuslt);
        setUserSave(reuslt);
      }
    };

    if (users.length === 0) fristLoad();
  }, [users, setUser]);

  // Search
  useEffect(() => {
    if (busca.length >= 3) {
      const result = filterByValue(users, busca);
      setUser(result);
    } else if (busca === "") {
      setUser(userSave);
    }
  }, [busca, userSave, users]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
        Cadastrados
        <span
          style={{
            background: "#ccc",
            display: "inline-block",
            width: 50,
            textAlign: "center",
            borderRadius: 5,
            marginLeft: 10,
          }}
        >
          {users.length}
        </span>
      </Typography>
      <TextField
        id="outlined-basic"
        label="Busca"
        variant="outlined"
        fullWidth
        sx={{ m: 1 }}
        onChange={(e) => {
          setBusca(e.target.value);
        }}
      />

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row, i) => (
              <Row key={i} row={row} loadingData={loadData} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Row(props) {
  const { row, loadingData } = props;
  const [open, setOpen] = useState(false);
  const status = JSON.parse(row?.info);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cardImage, setCardImage] = useState();

  const onFileUpload = async (file) => {
    // Client ID
    const clientId = "e372a281e610ee8",
      auth = "Client-ID " + clientId;

    // Creating an object of formData
    var formData = new FormData();
    formData.append("image", file, "GHJQTpX.jpeg");
    formData.append("type", "image");
    formData.append("title", "Simple upload");
    formData.append("description", "This is a simple image upload in Imgur");

    // Making the post request
    await fetch("https://api.imgur.com/3/image/", {
      // API Endpoint
      method: "POST", // HTTP Method
      body: formData, // Data to be sent
      headers: {
        // Setting header
        Authorization: auth,
        Accept: "application/json",
      },
    })
      // Handling success
      .then(async (res) => {
        const results = await res.json();
        const { data } = results;
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const result = await axios.post(
          `/confirmation.php`,
          { foto: data?.link, id: row.id, confirmation: true },
          config
        );
        console.log(result);
        if (result.status === 200) {
          setIsCameraOpen(false);
          setOpen(false);
          loadingData();
        }
      })
      .catch((err) => alert("Failed") && console.log(err));
  };

  return (
    <>
      <TableRow
        style={{
          backgroundColor: status?.confirmation ? "#2ecc71" : "#95a5a6",
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <Iconify
                icon={"eva:arrow-up-outline"}
                sx={{
                  width: 28,
                  height: 28,
                  color: "black",
                }}
              />
            ) : (
              <Iconify
                icon={"eva:arrow-down-outline"}
                sx={{
                  width: 28,
                  height: 28,
                  color: "black",
                }}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.nome}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              Email: {row.email} <br />
              Telefone: {row.phone}
              <a
                target="_blank"
                href={`https://wa.me/55${row.phone}?text=Oi%21%20Hoje%20%C3%A9%20o%20grande%20dia%20da%20%2A%E2%80%99Imers%C3%A3o%20ao%20Discipulado%E2%80%99%21%2A%0A%0APrepare-se%20para%20uma%20jornada%20intensa%20e%20transformadora%20que%20elevar%C3%A1%20todos%20os%20aspectos%20da%20sua%20vida%20a%20um%20novo%20patamar%21%20Sua%20presen%C3%A7a%20%C3%A9%20essencial%20para%20tornar%20este%20evento%20extraordin%C3%A1rio.%20%0A%0AMal%20podemos%20esperar%20para%20compartilhar%20insights%20e%20experi%C3%AAncias%20incr%C3%ADveis%20com%20voc%C3%AA.%20%0A%0AVamos%20fazer%20deste%20dia%20memor%C3%A1vel%20juntos%21%20At%C3%A9%20breve%21%0A%0AConfirme%20com%20um%20%2AOK%2A`}
                rel="noreferrer"
              >
                Whatsapp Link {row.phone}
              </a>
              <br />
              <br />
              {row.foto !== "" && (
                <img src={row.foto} alt={row.name} width={400} />
              )}
              <br />
              <br />
              {isCameraOpen && (
                <Camera
                  onCapture={(blob) => setCardImage(blob)}
                  onClear={() => setCardImage(undefined)}
                />
              )}
              {cardImage && (
                <Button
                  variant="contained"
                  onClick={() => {
                    onFileUpload(cardImage);
                  }}
                  sx={{ mt: 2 }}
                >
                  Salvar
                </Button>
              )}
              {/* {cardImage && (
                <div>
                  <h2>Preview</h2>
                  <Preview src={cardImage && URL.createObjectURL(cardImage)} />
                </div>
              )} */}
              <Button
                variant="contained"
                onClick={() => {
                  if (!isCameraOpen) {
                    setIsCameraOpen(true);
                  } else {
                    setIsCameraOpen(false);
                    setCardImage(undefined);
                  }
                }}
                sx={{ mt: 2 }}
              >
                {!isCameraOpen ? "Abrir Camera" : "Fechar Camera"}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
