import { useState, useEffect } from "react";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { Box, Button, styled, Typography, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// components
import { FormProvider, RHFTextField } from "../components/hook-form";
import Iconify from "./Iconify";

// utils
import axios from "../utils/axios";
// img
import logoImg from "../assets/logo.png";
import bgImg from "../assets/bg.png";

const Header = () => {
  const [users, setUser] = useState(null);
  const [obrgiado, setObg] = useState(false);
  const [erro, setErro] = useState(false);
  const [usSave, setusSave] = useState({});

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    // tamanhos
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    // cor de fundo
    background: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  const TroncosSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimo 5 caracteres")
      .required("Campo obrigatorio"),
    email: Yup.string().email("Email invalido").required("Campo obrigatorio"),
    phone: Yup.number()
      .min(10, "Minimo 10 digitos")
      .typeError("Telefone precisar ter somente numero digitados")
      .required("Campo obrigatorio"),
  });

  const defaultValues = {
    name: "",
    email: "",
    phone: "",
  };

  const methods = useForm({
    resolver: yupResolver(TroncosSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Add Labels to object will send
    const userRes = await axios.post(`/save.php`, data);
    if (userRes.data.success) {
      setusSave(data);
      setObg(true);
    } else {
      setErro(true);
    }
  };

  // Effects
  useEffect(() => {
    // Initial Effect
    const fristLoad = async () => {
      // Requests
      const usersRes = await axios.get(`/users.php`);

      if (usersRes.status) setUser(usersRes.data);
    };

    if (users === null) fristLoad();
  }, [users, setUser]);

  if (users?.vagas >= 1) {
    return (
      <CustomBox component="header" sx={{ color: "white" }}>
        <BoxText component="section">
          <img src={logoImg} style={{ width: "70%" }} alt={"logo"} />

          {!obrgiado && !erro && (
            <>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Integração Ágape Church
              </Typography>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Vagas Restantes
                <span
                  style={{
                    background: "#1976d2",
                    display: "inline-block",
                    width: 50,
                    textAlign: "center",
                    marginLeft: 10,
                  }}
                >
                  {users?.vagas}
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Iconify
                  icon={"tabler:calendar-filled"}
                  sx={{
                    width: 28,
                    height: 28,
                    color: "white",
                  }}
                />
                Abril 12 Sexta feira às 19:30H
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: 0,
                }}
              >
                <Iconify
                  icon={"tabler:calendar-filled"}
                  sx={{
                    width: 28,
                    height: 28,
                    color: "white",
                  }}
                />
                Abril 13 Sábado às 14:00H
              </Box>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Você que está recebendo esse convite para inscrição é sem dúvida
                um escolhido para viver o Reino de Deus na terra. O evento
                integração é para colocá-los dentro dessa embarcação, viver uma
                nova jornada juntos, integrada no corpo de Cristo (Igreja). Não
                existe célula viva fora do corpo, portanto é uma questão de
                sobrevivência e de oxigenação.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Você irá conhecer a Visão, missão e valores que carregamos como
                família. Será um tempo de muito aprendizado, onde temas
                específicos serão abordados para trazer consciência plena.
                Permita-se viver os propósitos que Deus tem na sua vida! Hoje
                inicia uma jornada de crescimento espiritual da sua fé e
                consciência sobre o evangelho.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Sejam bem vindos Att. Pastor Felipe Mokfa
              </Typography>
              <Box>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3} mt={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="name"
                          label="Nome completo"
                          variant="outlined"
                          sx={{
                            background: "#ffffff2b",
                            borderRadius: 1,
                          }}
                          inputProps={{ style: { color: "white" } }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="email"
                          label="Melhor Email"
                          variant="outlined"
                          sx={{
                            background: "#ffffff2b",
                            borderRadius: 1,
                          }}
                          inputProps={{ style: { color: "white" } }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="phone"
                          label="Telefone"
                          variant="outlined"
                          sx={{
                            background: "#ffffff2b",
                            borderRadius: 1,
                          }}
                          inputProps={{ style: { color: "white" } }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      sx={{}}
                    >
                      ME INSCREVER GRATUITAMENTE
                    </LoadingButton>
                  </Box>
                </FormProvider>
              </Box>
            </>
          )}
          {obrgiado && !erro && (
            <Box>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Olá {usSave?.name}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Obrigado por se inscrever no nosso dicipulado. Estamos muito
                felizes em ter você conosco!
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Em breve entraremos em contato com você com mais informações
                sobre o discipulado, mas, enquanto isso, você pode acompanhar
                nossas redes sociais para saber mais sobre o que vai acontecer.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Estamos ansiosos para vê-lo lá!
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Atenciosamente
              </Typography>
              <Typography variant="body1">
                Pr. Felipe Mokfa - Igreja Agape Church
              </Typography>
              <Button
                href="https://www.instagram.com/agape.church.oficial/"
                sx={{ mt: 2 }}
              >
                Acesse o Instagram
              </Button>
            </Box>
          )}

          {erro && (
            <Box>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Olá {usSave?.name}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Lamentamos informar que houve um erro ao cadastrar você em nosso
                sistema. Por favor, tente novamente mais tarde.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Se você continuar tendo problemas, entre em contato conosco pelo
                instagram
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Agradecemos sua compreensão.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Atenciosamente
              </Typography>
              <Typography variant="body1">
                Pr. Felipe Mokfa - Igreja Agape Church
              </Typography>
              <Button
                color="secondary"
                href="https://www.instagram.com/mulheres_de_posicionamento/"
                sx={{ mt: 2 }}
              >
                Acesse o Instagram
              </Button>
            </Box>
          )}
        </BoxText>

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              flex: "1",
              paddingTop: "1px",
              alignSelf: "center",
            },
            [theme.breakpoints.up("md")]: {
              flex: "2",
              alignSelf: "flex-start",
            },
          })}
        ></Box>
      </CustomBox>
    );
  }

  if (users?.vagas <= 0) {
    return (
      <>
        <CustomBox component="header" sx={{ color: "white" }}>
          {/*  Box text  */}
          <BoxText component="section">
            <img src={logoImg} style={{ width: "70%" }} alt={"logo"} />
            <Typography variant="h3" sx={{ mt: 3, fontWeight: 700 }}>
              Vagas Esgotadas
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Lamentamos informar que as vagas para o nosso Discipulado estão
              esgotadas. Recebemos uma resposta muito positiva à nossa campanha
              e estamos muito felizes em ver que tantas pessoas estão
              interessadas em participar.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Estamos trabalhando para aumentar a capacidade do discipulado, mas
              por enquanto não temos vagas disponíveis. Se você estiver
              interessado em participar, pedimos que você se inscreva em nossa
              lista de espera. Assim que tivermos mais vagas disponíveis,
              entraremos em contato com você.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Agradecemos sua compreensão e esperamos vê-lo no nosso próximo
              discipulado!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Atenciosamente, Pr. Felipe Mokfa - Igreja Agape Church
            </Typography>
          </BoxText>
        </CustomBox>
      </>
    );
  }
};

export default Header;
